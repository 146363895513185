canvas {
    display: block;
    margin: 20px;
}

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
