.content {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100vh;
}


.profile-photo img {
  width: 100px;
  border-radius: 50%;
}

.name {
  color: darkorange;
  font-size: calc(12px + 2vmin);
  margin: 0;
}

.occupation {
  color: gray;
  font-size: calc(2px + 2vmin);
  margin: 0;
}

.about {
  color: antiquewhite;
  text-align: center;
  font-size: calc(4px + 2vmin);
  width: calc(300px + 5*2vmin);
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials img {
  margin-left: 12px;
  width: 50px;
  height: 50px;
}
